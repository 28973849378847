import React from 'react';
import {Row} from 'reactstrap';

function Reactions() {
  return (
    <Row>
      <div className="col-xs-12 col-lg-3">
        &nbsp;
      </div>
      <div className="col-xs-12 col-lg-9">
        <div className="reactions-wrapper">
          <h2>Reactions</h2>
          <p>
            Twitter threads:
          </p>
          <ul>
            <li>
              <a href="https://twitter.com/search?q=indefenseofmerit.org&src=typed_query&f=live" target="_blank" rel="noopener noreferrer">indefenseofmerit.org</a>
            </li>
            <li>
              <a href="https://twitter.com/search?q=in defense of merit in science&src=typed_query&f=top" target="_blank" rel="noopener noreferrer">"In Defense of Merit In Science"</a>
            </li>
            <li>
              <a href="https://twitter.com/search?q=A%20Paper%20That%20Says%20Science%20Should%20Be%20Impartial%20Was%20Rejected%20From%20Major%20Journals.%20You%20Can’t%20Make%20This%20Up.&src=typed_query&f=live" target="_blank" rel="noopener noreferrer">"A Paper That Says Science Should Be Impartial Was Rejected From Major Journals. You Can’t Make This Up."</a>
            </li>
            <li>
              <a href="https://twitter.com/search?q=The%20hurtful%20idea%20of%20scientific%20merit&src=typed_query&f=top" target="_blank" rel="noopener noreferrer">"The Hurtful Idea of Scientific Merit"</a>
            </li>
          </ul>

          <hr/>

          <ul>
            <li>
              <a href="https://scholar.google.com/scholar?oi=bibs&hl=en&cites=10788071717518942414&inst=7943137549644843768" target="_blank" rel="noopener noreferrer">Google Scholar citation report</a>
            </li>
          </ul>

          <hr/>

          <ul>
            <li>
              Abhishek Saha in Presser: <a
              href="https://www.pressermag.com/november-2024/universities-must-recommit-to-excellence-and-reject-loyalty-oaths"
              target="_blank" rel="noopener noreferrer">Universities Must Recommit to Excellence and Reject Political
              Loyalty Oaths</a>. (November 2024)
            </li>
            <li>
              Richard McNally in the Journal of Controversial Ideas: <a
              href="https://www.journalofcontroversialideas.org/article/4/2/275"
              target="_blank" rel="noopener noreferrer">Is Clinical Psychological Science Infected by Racism and White
              Supremacy?</a> (October 30, 2024)
            </li>
            <li>
              Mike Gruntman, in Journal of Space Safely Engineering: <a
              href="https://www.sciencedirect.com/science/article/pii/S2468896724001101?dgcid=author"
              target="_blank" rel="noopener noreferrer">Master of Science in Astronautical Engineering degree at the
              University of Southern California for the space industry</a>. (August 14, 2024)
            </li>
            <li>
              David Lillis, John Raine, Peter Schwerdtfeger, Rex Ahdar, Cathy Downes, on Basset, Brash, and Hide: <a
              href="https://www.bassettbrashandhide.com/post/restoring-the-standing-and-reputation-of-science-in-new-zealand-a-letter-to-the-coalition-governmen"
              target="_blank" rel="noopener noreferrer">Restoring the Standing and Reputation of Science in New Zealand:
              a Letter to the Coalition Government.</a> (August 2, 2024)
            </li>
            <li>
              Robert P. George and Anna I. Krylov in The Chronicle of Higher Education: <a
              href="https://www.chronicle.com/article/the-ruthless-politicization-of-science-funding?sra=true"
              target="_blank" rel="noopener noreferrer">The Ruthless Politicization of Science Funding.</a> (July 26,
              2024)
            </li>
            <li>
              I.R. Efimov, J.S. Flier, R.P. George, A.I. Krylov, L.S. Maroja, J. Schaletzky, J. Tanzman, and A. Thompson
              in Frontiers in Research Metrics and Analytics: <a
              href="https://www.frontiersin.org/journals/research-metrics-and-analytics/articles/10.3389/frma.2024.1418065/full#B95"
              target="_blank" rel="noopener noreferrer">Politicizing science funding undermines p
              ublic trust in science, academic freedom, and the unbiased generation of knowledge.</a>. (July 22, 2024)
            </li>
            <li>
              Anna Krylov and Jay Tanzman on HxSTEM: <a
              href="https://hxstem.substack.com/p/politicizing-science-funding-undermines"
              target="_blank" rel="noopener noreferrer">Politicizing Science Funding Undermines Public Trust in Science,
              Academic Freedom, and the Unbiased Generation of Knowledge.</a>. (July 17, 2024)
            </li>
            <li>
              Andi Shae-Napier in The College Fix: <a
              href="https://www.thecollegefix.com/dawkins-foundation-leader-speaks-out-against-dogmatic-social-justice-infiltrating-science/"
              target="_blank" rel="noopener noreferrer">Dawkins Foundation leader speaks out against ‘dogmatic social
              justice’
              infiltrating science</a>. (May 23, 2024)
            </li>
            <li>
              Johannes Zeller in Skeptical Inquirer: <a
              href="https://skepticalinquirer.org/exclusive/the-german-dilemma-continues-skepticism-in-the-face-of-ideological-conflict/"
              target="_blank" rel="noopener noreferrer">Scientific Skepticism vs CSJ Ideology in a German Skeptical
              Society</a>. (April 25, 2024)
            </li>
            <li>
              Robyn Blumner in the Skeptical Inquirer: <a
              href="https://skepticalinquirer.org/2024/02/defending-science-against-social-justice-dogmatism-and-identitarianism/"
              target="_blank" rel="noopener noreferrer">Defending Science against Social Justice Dogmatism and
              Identitarianism</a>. (April 24, 2024)
            </li>
            <li>
              Anna Krylov (USC) and Michael Strevens (NYU), <a href="https://www.youtube.com/watch?v=CzMHtDMnHeI"
                                                               target="_blank" rel="noopener noreferrer">a conversation
              about the "In Defense of Merit in Science" paper</a>, moderated by Steven Brint (UCR) and hosted by CIVIC
              UCR. (April 18, 2024)
            </li>
            <li>
              Alexander Gabovich and Volodymyr Kuznetsov in European Journal of Physics: <a
              href="https://doi.org/10.1088/1361-6404/ad2fd7" target="_blank" rel="noopener noreferrer">Eponyms in
              physics: useful tools and cultural heritage.</a> (April 8, 2024)
            </li>
            <li>
              Trish Nayna Schwerdtle and Carl LeVan: <a href="https://www.youtube.com/watch?v=ZCzsRs64qS8"
                                                        target="_blank" rel="noopener noreferrer">"Is Merit in Science
              Under Attack?"</a> on YouTube (March 2, 2024)
            </li>
            <li>
              Anna Krylov and Jay Tanzman, in T-Invariant (in Russian): <a
              href="https://www.t-invariant.org/2024/02/strahovka-ot-vrednyh-znanij-kak-rabotaet-tsenzura-v-nauchnyh-publikatsiyah/"
              target="_blank" rel="noopener noreferrer">"Страховка от «вредных знаний». Как работает цензура в научных
              публикациях."</a> (February 12, 2024)
            </li>
            <li>
              David Lillis, on BreakingViews: <a
              href="https://breakingviewsnz.blogspot.com/2024/02/david-lillis-preserving-excellence-in.html"
              target="_blank" rel="noopener noreferrer">"Preserving Excellence in New Zealand Research."</a> (February
              3, 2024)
            </li>
            <li>David Lillis in BreakingViews: <a
              href="https://breakingviewsnz.blogspot.com/2024/01/david-lillis-future-of-university.html" target="_blank"
              rel="noopener noreferrer">The
              Future of the University</a> (January 22, 2024)
            </li>
            <li>
              Howard Muncy, in the Academic Freedom Alliance newsletter: <a
              href="https://academicfreedom.org/unidentified-flying-harms-and-other-problems-an-interview-with-professor-lee-jussim/"
              target="_blank"
              rel="noopener noreferrer">"Unidentified Flying Harms and Other Problems: An Interview with Professor Lee
              Jussim."</a> (January 3, 2024)
            </li>
            <li>
              John Staddon, in The Daily Sceptic: <a
              href="https://dailysceptic.org/2023/12/24/this-is-what-decolonization-looks-like/" target="_blank"
              rel="noopener noreferrer">"This is What Decolonisation Looks Like."</a> (December 24,
              2023)
            </li>
            <li>
              Anna Krylov and Jay Tanzman, on HxSTEM: <a
              href="https://hxstem.substack.com/p/spotlight-on-scientific-censorship" target="_blank"
              rel="noopener noreferrer">"Spotlight on scientific censorship: A virtual collection."</a> (December 24,
              2023)
            </li>
            <li>
              Abhishek Saha on SSRN: <a
              href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4592328"
              target="_blank" rel="noopener noreferrer">Equality, Diversity and Inclusion in the mathematics community:a
              perspective on data and policy</a>. (October, 2023).
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/12/11/fareed-zakaria-decries-the-movement-to-make-merit-come-second-to-identity/"
              target="_blank"
              rel="noopener noreferrer">"Fareed Zakaria decries the movement to make academic merit less important than
              identity and diversity."</a> (December 11, 2023)
            </li>
            <li>
              Matthew Giffin, The College Fix: <a
              href="https://www.thecollegefix.com/scientists-not-media-censor-science-study/" target="_blank"
              rel="noopener noreferrer">"Scientists urge clearer publishing standards to reduce bias, regain public
              trust."</a> (December 1, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/11/25/censorship-in-science-a-new-paper-and-analysis/"
              target="_blank" rel="noopener noreferrer">"Censorship in science: a new paper and analysis."</a> (November
              25, 2023)
            </li>
            <li>
              Marcel Kuntz, on Factuel (in French): <a
              href="https://factuel.media/blogs/blog-articles/la-censure-en-science-et-ses-motivations-ideologiques_ba_20586635"
              target="_blank" rel="noopener noreferrer">"La censure en science et ses motivations
              idéologiques."</a> (November 24, 2023)
            </li>
            <li>
              Jerry Coyne, on WhyEvolutionIsTrue: <a
              href="https://whyevolutionistrue.com/2023/11/17/queensland-university-of-technology-completely-ditches-merit-based-hiring/"
              target="_blank" rel="noopener noreferrer">"Queensland University of Technology completely ditches
              merit-based hiring, favoring gender, “looks”, and personality."</a> (November 16, 2023)
            </li>
            <li>
              Jerry Coyne, on WhyEvolutionIsTrue: <a
              href="https://whyevolutionistrue.com/2023/11/16/the-journal-science-on-positionality-statements/"
              target="_blank" rel="noopener noreferrer">The journal Science discusses positionality
              statements.</a> (November 16, 2023)
            </li>
            <li>
              Rachel Zamzow, in Science magazine: <a
              href="https://www.science.org/content/article/should-scientists-include-their-race-gender-or-other-personal-details-papers"
              target="_blank" rel="noopener noreferrer">Should scientists include their race, gender, or other personal
              details in papers?</a> (November 1, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution is True: <a
              href="https://whyevolutionistrue.com/2023/10/27/animal-behavior-society-requires-dei-statements-for-student-grants/"
              target="_blank" rel="noopener noreferrer">"Animal Behavior Society requires DEI statements for student
              grants"</a>. (October 27, 2023)
            </li>
            <li>
              Steven Brint and Komi Frey, in Research & Occasional Paper Series, Berkeley Center for Studies in Higher
              Education: <a
              href="https://escholarship.org/content/qt3pt9m168/qt3pt9m168_noSplash_ab9a54919c48187c49c6c25c0b566fbd.pdf?t=s2z9id"
              target="_blank" rel="noopener noreferrer">"Is the University of California Drifting Toward Conformism? The
              Challenges of Representation and the Climate for Academic Freedom"</a>. (October 15, 2023)
            </li>
            <li>
              Peter Schwerdtfeger, David Lillis and John Raine on BreakingViews: <a
              href="https://breakingviewsnz.blogspot.com/2023/10/peter-schwerdtfeger-david-lillis-and.html"
              target="_blank" rel="noopener noreferrer">New Zealand’s Tertiary Education Sector in Deep Financial
              Crisis</a>. (October 13, 2023)
            </li>
            <li>
              Benjamin K. Diggles et al, in Reviews in fisheries science & Aquaculture: <a
              href="https://doi.org/10.1080/23308249.2023.2257802" target="_blank" rel="noopener noreferrer">"Reasons to
              Be Skeptical about Sentience and Pain in Fishes and Aquatic Invertebrates."</a> (October 4, 2023)
            </li>
            <li>
              Anna Krylov and Jay Tanzman, on HxSTEM: <a
              href="https://hxstem.substack.com/p/fighting-the-good-fight-in-an-age" target="_blank"
              rel="noopener noreferrer">Fighting the Good Fight in an Age of Unreason—A New Dissident Guide</a>.
              (October 1, 2023)
            </li>
            <li>
              Lee Jussim, on Unsafe Science: <a
              href="https://unsafescience.substack.com/p/victory-lap?utm_source=post-email-title&publication_id=762897&post_id=137052657&utm_campaign=email-post-title&isFreemail=false&r=ggupy&utm_medium=email"
              target="_blank" rel="noopener noreferrer">Victory Lap</a>. (September 16, 2023)
            </li>
            <li>
              Luana Maroja, Bernard Trout, and Scott Turner, on Restoring the Sciences: <a
              href="https://www.youtube.com/watch?v=tanC8vl9_Hc" target="_blank" rel="noopener noreferrer">Does Merit
              Matter?</a> (September 15, 2023)
            </li>
            <li>
              William M. London, in Skeptical Inquirer, <a
              href="https://skepticalinquirer.org/2023/08/merit-in-science-defended/" target="_blank"
              rel="noopener noreferrer">Merit in Science Defended</a>. (September 9, 2023)
            </li>
            <li>
              ScioSophia, on SciChat Saturday: <a href="https://www.youtube.com/watch?v=MlJ4_LSQems" target="_blank"
                                                  rel="noopener noreferrer">Academics vs. "Academics" with Lee
              Jussim.</a>. (August 12,2023)
            </li>
            <li>
              Anna Krylov, in IntLex Club: <a href="https://www.youtube.com/watch?v=1CibvhdHcVw" target="_blank"
                                              rel="noopener noreferrer">Merit-based science is effective and fair: How
              such a banal idea has become controversial</a>. (August 8,2023)
            </li>
            <li>
              David Bailey, on Science meet religion: <a
              href="https://www.sciencemeetsreligion.org/2022/11/is-modern-science-socially-constructed-and-forever-tentative/"
              target="_blank" rel="noopener noreferrer">Is modern science socially constructed and forever tentative</a>?
              (August 7,2023)
            </li>
            <li>
              Peter Schwerdtfeger, John Raine, and David Lillis on BreakingViews.co.nz: <a
              href="https://breakingviewsnz.blogspot.com/2023/07/peter-schwerdtfeger-john-raine-and.html"
              target="_blank" rel="noopener noreferrer">Post-modernism and the Degrading of Education in New
              Zealand.</a> (July 24,2023)
            </li>
            <li>
              Martin Krause, on El Foro Y El Bazar (in Spanish): <a
              href="https://bazar.ufm.edu/merito-simplemente-esfuerzo-mercado-no-remunera-la-ciencia-tampoco/"
              target="_blank" rel="noopener noreferrer">Si el mérito es simplemente esfuerzo, el mercado no remunera por
              eso, y la ciencia tampoco.</a> (July 21,2023)
            </li>
            <li>
              Peter Singer, in the interview with Project Syndicate: <a
              href="https://www.project-syndicate.org/onpoint/an-interview-with-peter-singer-russia-protests-artificial-intelligence-animal-liberation-now-2023-07"
              target="_blank" rel="noopener noreferrer">Peter Singer says more</a> (July 18,2023)
            </li>
            <li>
              On Viral podcast: Episode 52: <a
              href="https://viralpod.podbean.com/e/episode-52-truth-and-merit-in-science/" target="_blank"
              rel="noopener noreferrer">Truth and Merit in Science</a> (July 18,2023)
            </li>
            <li>
              Luana Maroja and Jerry Coyne, on Skeptical Inquirer: <a
              href="https://skepticalinquirer.org/video/the-ideological-subversion-of-biology-jerry-coyne-and-luana-maroja/"
              target="_blank" rel="noopener noreferrer">The Ideological Subversion of Biology</a> (July 6,2023)
            </li>
            <li>
              John Raine, David Lillis, Peter Schwerdtfeger, on BreakingViews.co.nz: <a
              href="https://breakingviewsnz.blogspot.com/2023/06/john-raine-david-lillis-peter.html" target="_blank"
              rel="noopener noreferrer">Where are our Universities Heading?</a> (June 28,2023)
            </li>
            <li>
              Jerry Coyne and Luana Maroja, in Skeptical Inquirer: <a
              href="https://skepticalinquirer.org/2023/06/the-ideological-subversion-of-biology/" target="_blank"
              rel="noopener noreferrer">The Ideological Subversion of Biology</a>. (June 20,2023)
            </li>
            <li>
              Brett Alan Williams, on Medium: <a
              href="https://brettalanwilliams.medium.com/finally-the-left-has-joined-the-right-in-the-war-on-science-7ffe18398b63"
              target="_blank" rel="noopener noreferrer">Finally, the Left has Joined the Right in the War on Science</a>.
              (June 20, 2023)
            </li>
            <li>
              Anna Krylov and Jay Tanzman, on HxSTEM: <a
              href="https://hxstem.substack.com/p/critical-social-justice-subverts" target="_blank"
              rel="noopener noreferrer">Critical Social Justice Subverts Scientific Publishing</a>. (June 12, 2023)
            </li>
            <li>
              ScioSophia, on SciChat Saturday: <a href="https://www.youtube.com/watch?v=1V6PR4BVO-g" target="_blank"
                                                  rel="noopener noreferrer">The Peril of Politicizing Science w/Anna
              Krylov</a>. (June 10, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/06/10/lee-jussim-analyzes-the-criticism-of-our-paper-on-science-and-merit/"
              target="_blank" rel="noopener noreferrer">Lee Jussim analyzes the criticism of our paper on science and
              merit</a>. (June 10, 2023)
            </li>
            <li>
              Spencer Case, Alex Byrne, and Matt Lutz on Micro-Digressions: A Philosophy Podcast: <a
              href="https://www.buzzsprout.com/956725/13007346-wokeness-in-science-critiquing-a-critique"
              target="_blank" rel="noopener noreferrer">Wokeness in Science: Critiquing a critique</a>. (June 9, 2023)
            </li>
            <li>
              Lee Jussim, on Unsafe Science: <a
              href="https://unsafescience.substack.com/p/the-bizarre-bad-criticisms-of-our" target="_blank"
              rel="noopener noreferrer">The Bizarre Bad Criticisms of Our Merit Paper</a>. (June 8, 2023)
            </li>
            <li>
              Tyler Cowen, on Marginal Revolution: <a
              href="https://marginalrevolution.com/marginalrevolution/2023/06/my-excellent-conversation-with-peter-singer.html"
              target="_blank" rel="noopener noreferrer">My excellent conversation with Peter Singer</a>. (June 7, 2023)
            </li>
            <li>
              Jerry Coyne on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/06/02/our-wall-street-journal-op-ed-free-at-last/"
              target="_blank" rel="noopener noreferrer">Our Wall Street Journal op-ed: free at last!</a> (June 2, 2023)
            </li>
            <li>
              ScioSophia, on YouTube: <a href="https://www.youtube.com/watch?v=u2Xy477MD94&t=2s" target="_blank"
                                         rel="noopener noreferrer">Feedback on "In Defense of Merit"</a>. (May, 2023)
            </li>
            <li>
              Małgorzata Koraszewska on On Listy z Nazego Sadu (in Polish): <a
              href="https://www.listyznaszegosadu.pl/nauka/raniacaa-idea-merytorycznych-podstaw-naukia" target="_blank"
              rel="noopener noreferrer">‘Raniąca’ idea merytorycznych podstaw nauki</a>. (May 29, 2023)
            </li>
            <li>
              Sebastian Kozuch, on HxSTEM: <a href="https://hxstem.substack.com/p/aiming-for-a-neutral-ph-in-stem"
                                              target="_blank" rel="noopener noreferrer">Aiming for a neutral pH in
              STEM</a>. (May 29, 2023)
            </li>
            <li>
              Wikipedia, cited in <a href="https://en.wikipedia.org/wiki/Listener_letter_on_science" target="_blank"
                                     rel="noopener noreferrer">Listener letter on science article</a>. (May 29, 2023)
            </li>
            <li>
              Readers' responses to Pamela Paul's op-ed, in NYT: <a
              href="https://www.nytimes.com/2023/05/27/opinion/letters/science-diversity-equity-inclusion.html"
              target="_blank" rel="noopener noreferrer">Applying D.E.I. to Science</a>. (May 27, 2023)
            </li>
            <li>
              Andrés Alsina, on La Onda Digital (in Spanish): <a href="https://www.laondadigital.uy/archivos/70876"
                                                                 target="_blank" rel="noopener noreferrer">En EEUU se
              censuran libros y ciencia</a>. (May 27, 2023)
            </li>
            <li>
              Joshua Katz, in City Journal: <a href="https://www.city-journal.org/article/a-hearing-for-heterodoxy"
                                               target="_blank" rel="noopener noreferrer">A Hearing for Heterodoxy</a>.
              (May 25, 2023)
            </li>
            <li>
              Niccolò Magnani, on ilsussidiario.net (in Italian): <a
              href="https://www.ilsussidiario.net/news/ideologia-woke-mina-loccidente-scienziati-francia-cosi-si-cancella-il-dissenso/2541484/"
              target="_blank" rel="noopener noreferrer">"Ideologia woke mina l’Occidente”/ Scienziati Francia: “così si
              cancella il dissenso"</a>. (May 23, 2023)
            </li>
            <li>
              Diane Brady, in Forbes Magazine: <a
              href="https://www.forbes.com/sites/dianebrady/2023/05/22/philosopher-peter-singer-defends-animals-merit--and-controversy/?sh=6b8200a171ae&utm_source=SINGER%2023%20-%20EOI%20-%20WT&utm_medium=email&utm_campaign=SINGER%20WT%2023%20-%20Last%20Chance%20-%20Articles&_kx=Uo_B9sEAyL2BVmtbmaPpDiBHU1cntAPOKHl-BzDZhR0%3D.WYzaZ8"
              target="_blank" rel="noopener noreferrer">Philosopher Peter Singer Defends Animals, ‘Merit’ —And Courting
              Controversy</a>. (May 23, 2023)
            </li>
            <li>
              Peter Singer, <a href="https://www.youtube.com/watch?v=jW2O2WAhVfw&t=268s" target="_blank"
                               rel="noopener noreferrer">on Forbes's YouTube channel;</a> relevant portion from
              9:30–13:00. (May 23, 2023)
            </li>
            <li>
              Scott Turner, in Minding the Campus: <a
              href="https://www.mindingthecampus.org/2023/05/23/in-defense-of-merit-is-it-too-late/" target="_blank"
              rel="noopener noreferrer">In Defense of Merit: Is it too late?</a> (May 23, 2023)
            </li>
            <li>
              Anna Krylov and David Livingston (Dr. Space), on The Space Show: <a
              href="https://www.thespaceshow.com/show/19-may-2023/broadcast-4032-dr.-anna-krylov" target="_blank"
              rel="noopener noreferrer">USC Professor on the scientific method, science today and related items</a>.
              (May 19, 2023)
            </li>
            <li>
              Lisa Selin Davis, in The Free Press: <a
              href="https://www.thefp.com/p/how-therapists-became-social-justice-warriors" target="_blank"
              rel="noopener noreferrer">How Therapists Became Social Justice Warriors</a>. (May 17, 2023)
            </li>
            <li>
              ScioSophia, on YouTube: <a href="https://www.youtube.com/watch?v=u2Xy477MD94" target="_blank"
                                         rel="noopener noreferrer">Feedback on "In Defense of Merit"</a>. (May 17, 2023)
            </li>
            <li>
              Hamadryad, in Minding the Campus: <a href="https://www.mindingthecampus.org/2023/05/17/against-merit/"
                                                   target="_blank" rel="noopener noreferrer"> Against Merit</a>. (May
              17, 2023)
            </li>
            <li>
              Joanne Jacobs, on Thinking and Linking: <a
              href="https://www.joannejacobs.com/post/americans-like-merit-but-democrats-aren-t-so-sure" target="_blank"
              rel="noopener noreferrer">Americans like merit, but Democrats aren't so sure</a>. (May 16, 2023)
            </li>
            <li>
              John McWhorter, in New York Times: <a
              href="https://www.nytimes.com/2023/05/16/opinion/musicology-racism.html" target="_blank"
              rel="noopener noreferrer">Is Musicology Racist?</a> (May 16, 2023)
            </li>
            <li>
              Simon Lucas, on LinkedIn: <a href="https://www.linkedin.com/pulse/holdens-motte-thorps-bailey-simon-lucas"
                                           target="_blank" rel="noopener noreferrer">Holden’s Motte and Thorp’s
              Bailey</a>. (May 15, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/05/15/the-chat-bot-vs-the-editor-in-chief-of-science/"
              target="_blank" rel="noopener noreferrer">The Chat bot vs. the editor-in-chief of Science</a>. (May 15,
              2023)
            </li>
            <li>
              Dr. Apoorva Pauranik, in Mediawala (in Hindi): <a
              href="https://whyevolutionistrue.com/2023/05/12/holden-thrope-editor-of-science-goes-after-our-merit-paper/"
              target="_blank"
              rel="noopener noreferrer">https://mediawala.in/apni-bhasha-apna-vigyan-merit-in-science-and-social-science</a>.
              (May 14, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/05/12/holden-thrope-editor-of-science-goes-after-our-merit-paper/"
              target="_blank" rel="noopener noreferrer">Holden Thorp, editor of Science, goes after our merit paper</a>.
              (May 12, 2023)
            </li>
            <li>
              Ernest Mund, in European Scientist: <a
              href="https://www.europeanscientist.com/en/features/can-wokism-entail-damage-to-industrial-society/"
              target="_blank" rel="noopener noreferrer">Can wokism entail damage to industrial society?</a>, (May 11,
              2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution is True: <a
              href="https://whyevolutionistrue.com/2023/05/11/updated-press-release-of-our-merit-paper/" target="_blank"
              rel="noopener noreferrer">Updated reactions, pro and con, to our “merit” paper</a>. (May 11, 2023)
            </li>
            <li>
              Rui Teixeira, on Liberal Patriot: <a href="https://www.liberalpatriot.com/p/the-democrats-merit-problem"
                                                   target="_blank" rel="noopener noreferrer">The Democrats’ Merit
              Problem</a>. (May 11, 2023)
            </li>
            <li>
              John Derbyshire, on VDare.com: <a
              href="https://vdare.com/posts/a-defense-of-merit-in-science-can-only-be-published-in-the-journal-of-controversial-ideas"
              target="_blank" rel="noopener noreferrer">A Defense Of Merit In Science Can Only Be Published In The
              JOURNAL OF CONTROVERSIAL IDEAS</a>. (May 10, 2023)
            </li>
            <li>
              Letters to the editors, <a
              href="https://www.startribune.com/readers-write-debt-ceiling-merit-in-science-university-of-minnesota-interim-president/600273615/"
              target="_blank" rel="noopener noreferrer">Minneapolis Star Tribune</a>. (May 10, 2023)
            </li>
            <li>
              Teller Report: Andreas Bikfalvi: <a
              href="https://www.tellerreport.com/news/2023-05-07-andreas-bikfalvi--%22woke-ideology-threatens-even-science%22.BkUGsgrEh.html"
              target="_blank" rel="noopener noreferrer">"Woke ideology threatens even science"</a>. (May 7, 2023)
            </li>
            <li>
              Minneapolis Star Tribune: <a
              href="https://www.startribune.com/follow-the-science-not-the-agendas-of-the-scientists/600273103/"
              target="_blank" rel="noopener noreferrer">Follow the science, not the agendas of the scientists</a>. (May
              7, 2023)
            </li>
            <li>
              Paul Caron, on TaxProf blog: <a
              href="https://taxprof.typepad.com/taxprof_blog/2023/05/in-defense-of-merit-and-rigor-in-academic-journals.html"
              target="_blank" rel="noopener noreferrer">NY Times Op-Ed: In Defense Of Merit And Rigor In Academic
              Journals</a>. (May 6, 2023)
            </li>
            <li>
              David Lillis, on BreakingViews.co.nz: <a
              href="https://breakingviewsnz.blogspot.com/2023/05/david-lillis-fighting-for-science.html" target="_blank"
              rel="noopener noreferrer">Fighting for Science</a>. (May 6, 2023)
            </li>
            <li>
              Bernie Bowels, on Pete Kaliner show: <a
              href="https://wbt.com/576734/science-publications-reject-defense-of-merit/" target="_blank"
              rel="noopener noreferrer">Science publications reject defense of merit</a>. (May 5, 2023)
            </li>
            <li>
              Scott Greenfield, on Simple Justice: <a
              href="https://blog.simplejustice.us/2023/05/04/taking-diversity-beyond-reason/" target="_blank"
              rel="noopener noreferrer">Taking Diversity Beyond Reason</a>. (May 4, 2023)
            </li>
            <li>
              Madras Tribune: <a
              href="https://madrastribune.com/2023/05/04/29-scientists-wrote-a-paper-defending-merit-in-science-science-journals-rejected-it-hotair/"
              target="_blank" rel="noopener noreferrer">29 scientists wrote a paper defending merit in science. Science
              journals rejected it</a>. (May 4, 2023)
            </li>
            <li>
              Howard Winet, in the WSJ: <a href="https://www.wsj.com/articles/science-merit-schools-ideology-454a11a"
                                           target="_blank" rel="noopener noreferrer">The Attack on Scientific Merit in
              the Schools</a>. (May 4, 2023)
            </li>
            <li>
              Pamela Paul, in the New York Times: <a
              href="https://www.nytimes.com/2023/05/04/opinion/science-evidence-merits.html" target="_blank"
              rel="noopener noreferrer">A Paper That Says Science Should Be Impartial Was Rejected From Major Journals.
              You Can’t Make This Up</a>. (May 4, 2023)
            </li>
            <li>
              John Sexton, on Hot Air: <a
              href="https://hotair.com/john-s-2/2023/05/04/29-scientists-wrote-a-paper-defending-merit-in-science-science-journals-rejected-it-n548410"
              target="_blank" rel="noopener noreferrer">29 scientists wrote a paper defending merit in science. Science
              journals rejected it</a>. (May 4, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution Is True: <a
              href="https://whyevolutionistrue.com/2023/05/04/pamela-paul-of-the-nyt-touts-our-merit-paper/"
              target="_blank" rel="noopener noreferrer">Pamela Paul of the NYT touts our merit paper</a>. (May 4, 2023)
            </li>
            <li>
              AI, on Podcasts Worth a Listen: <a
              href="https://player.fm/series/paper-to-podcast/defending-merit-in-science" target="_blank"
              rel="noopener noreferrer">Defending Merit in Science</a>. (May 4, 2023)
            </li>
            <li>
              Andreas Bikfalvi in Le Figaro (in French): <a
              href="https://www.lefigaro.fr/vox/societe/andreas-bikfalvi-l-ideologie-woke-menace-jusqu-a-la-science-20230503"
              target="_blank" rel="noopener noreferrer">L’idéologie woke menace jusqu’à la science</a>. (May 4, 2023)
            </li>
            <li>
              Lee Jussim, on Unsafe Science: <a
              href="https://unsafescience.substack.com/p/not-all-academics?utm_source=post-email-title&publication_id=762897&post_id=118412947&isFreemail=false&utm_medium=email"
              target="_blank" rel="noopener noreferrer">Not All Academics</a>. (May 3, 2023)
            </li>
            <li>
              James Rogan on Common Sense Daily: <a href="https://rogan.substack.com/p/may-2-e10" target="_blank"
                                                    rel="noopener noreferrer">May 2 News Digest.</a>. (May 2, 2023)
            </li>
            <li>
              Thomas Thiel, in Frankfurter Allgemeine Zeitung (FAZ) (in German): <a
              href="https://www.faz.net/aktuell/karriere-hochschule/hoersaal/cancel-culture-publikation-ueber-das-leistungsprinzip-abgelehnt-18861627.html"
              target="_blank" rel="noopener noreferrer">Leistung unter Ideologieverdacht</a>. (May 2, 2023)
            </li>
            <li>
              Real Clear Education: <a
              href="https://www.realcleareducation.com/2023/05/03/the_039hurtful039_idea_of_scientific_merit_53403.html"
              target="_blank" rel="noopener noreferrer">The 'Hurtful' Idea of Scientific Merit</a>. (May 2, 2023)
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=6WYj5i2DIBo" target="_blank" rel="noopener noreferrer">ScioSophia
                discussed the article on her YouTube channel</a>. (May 2, 2023)
            </li>
            <li>
              Maggie Kelly, in The College Fix: <a
              href="https://www.thecollegefix.com/professors-publish-controversial-paper-defending-merit-in-science/"
              target="_blank" rel="noopener noreferrer">Professors publish ‘controversial’ paper defending merit in
              science</a>. (May 2, 2023)
            </li>
            <li>
              On Listy z Nazego Sadu (in Polish): <a
              href="https://www.listyznaszegosadu.pl/m/nauka/dwudziestu-dziewieciunaukowcalw-publikuje-artykul-w-obronie-merytorycznych-podstaw-w-nauce"
              target="_blank" rel="noopener noreferrer">Dwudziestu dziewięciunaukowców publikuje artykuł w obronie
              merytorycznych podstaw w nauce</a>. (May 1, 2023)
            </li>
            <li>
              American Institute of Physics (AIP) mentions our paper in their weekly newsletter FYI: <a
              href="https://www.aip.org/fyi/fyi-this-week/week-may-1-2023" target="_blank" rel="noopener noreferrer">Science
              policy news from AIP (in Research Management section)</a>. (May 1, 2023)
            </li>
            <li>
              On LLNL - The True Story: <a
              href="http://llnlthetruestory.blogspot.com/2023/04/scientists-defend-merit-in-science.html"
              target="_blank" rel="noopener noreferrer">Scientists Defend Merit in Science</a>. (April 30, 2023)
            </li>
            <li>
              Alex Tabarrok, on Policy Commons: <a
              href="https://policycommons.net/artifacts/3751939/in-defense-of-merit/4557250/" target="_blank"
              rel="noopener noreferrer">In Defense of Merit</a>. (April 30, 2023)
            </li>
            <li>
              Andreas Bikfalvi, on <a href="https://decolonialisme.fr/in-defense-of-merit-in-science/" target="_blank"
                                      rel="noopener noreferrer">Observatoire des idéologies identitaires</a> (in
              French). (April 30, 2023)
            </li>
            <li>
              Leslie Eastman, on Legal Insurrection: <a
              href="https://legalinsurrection.com/2023/04/scientists-begin-fighting-back-against-ideological-capture-of-their-research/"
              target="_blank" rel="noopener noreferrer">"Researchers Fight Back Against ‘Ideological Capture’ of Modern
              Science"</a>. (April 30, 2023)
            </li>
            <li>
              Ben Zeisloft, in Conservative News Daily and in Daily Wire: <a
              href="https://www.dailywire.com/news/jordan-peterson-blasts-academia-after-paper-defending-merit-rejected-for-hurtfulness?utm_source=partner&utm_campaign=conservnewsd"
              target="_blank" rel="noopener noreferrer">Dr. Jordan Peterson Blasts Academia After Paper Defending Merit
              Rejected For ‘Hurtfulness’</a>. (April 30, 2023)
            </li>
            <li>
              Center for Inquiry <a
              href="https://centerforinquiry.org/blog/without-regard-to-the-truth-or-the-law-morning-heresy-042823/"
              target="_blank" rel="noopener noreferrer">mentioned the paper on their blog</a>. (April 30, 2023)
            </li>
            <li>
              Caitlin Kirman, on <a href="https://greygirlbeast.livejournal.com/1760642.html" target="_blank"
                                    rel="noopener noreferrer">Dear Sweet Filthy World blog</a>. (April 30, 2023)
            </li>
            <li>
              Erich Vieth on Dangerous Intersection: <a
              href="https://dangerousintersection.org/2023/04/29/scientific-journals-mass-reject-article-promoting-merit-in-science/"
              target="_blank" rel="noopener noreferrer">Scientific Journals Mass-Reject Article Promoting Merit in
              Science</a>. (April 29, 2023)
            </li>
            <li>
              Ross Kaminski on TGIF Blogcast: <a
              href="https://koacolorado.iheart.com/featured/ross-kaminsky/content/2023-04-27-tgif-blogcast-phil-weiser-in-studio-sardines-ai-created-spam-friday/"
              target="_blank" rel="noopener noreferrer">Phil Weiser (in studio); Sardines; AI-created spam;
              Friday!</a> (April 28, 2023)
            </li>
            <li>
              Bari Weiss gave us a shout-out in her <a href="https://www.thefp.com/p/tgif-why-tucker-carlson-got-fired"
                                                       target="_blank" rel="noopener noreferrer">TGIF newsletter</a> at
              The Free Press. (April 28, 2023)
            </li>
            <li>
              Academic Freedom Alliance mentions the paper
              in <a href="https://academicfreedom.org/april-2023/" target="_blank" rel="noopener noreferrer">the monthly
              newsletter</a>. (April 28, 2023)
            </li>
            <li>
              Jerry Coyne, on Why Evolution is True: <a
              href="https://whyevolutionistrue.com/2023/04/28/our-big-paper-on-the-importance-of-placing-merit-over-ideology-in-science-and-an-op-ed-in-the-wsj/"
              target="_blank" rel="noopener noreferrer">Our big paper on the importance of placing merit over ideology
              in science, and an op-ed in the WSJ</a>. (April 28, 2023)
            </li>
            <li>
              Lee Jussim, on Unsafe Science: <a
              href="https://unsafescience.substack.com/p/our-defense-of-merit-in-science" target="_blank"
              rel="noopener noreferrer">Our Defense of Merit in Science</a>. (April 28, 2023)
            </li>
          </ul>

          <hr/>
        </div>
      </div>
    </Row>
  );
}

export default Reactions;
